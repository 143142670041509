<template>
  <!-- 配置模板 -->
  <a-breadcrumb>
    <a-breadcrumb-item>内容管理</a-breadcrumb-item>
    <a-breadcrumb-item>剧集状态变更日志</a-breadcrumb-item>
  </a-breadcrumb>
  <div class="content">
    <div class="tool-view">
      <!-- 剧名 -->
      <!-- <span class="tool-title">剧名：</span>
      <a-input v-model:value="nameModel" style="width: 180px;" placeholder="请输入" /> -->
      <!-- 操作 -->
      <!-- <a-button type="primary" style="margin-left: 16px;" @click="touchSearch">查询</a-button>
      <a-button style="margin-left: 16px;" @click="touchReset">重置</a-button>
      <div style="flex: 1;"></div> -->
      <!-- 其他操作 -->
      <!-- <a-button type="primary" @click="add"><plus-outlined />新增剧集</a-button> -->
    </div>
    <div class="table-box">
      <a-table
        :customRow="customRow"
        :data-source="dataSource"
        :columns="columns"
        :pagination="pagination"
        :row-key="record => record.id"
        :loading="isLoading"
        @change="handleTableChange"
      >
        <template #bodyCell="{ column, record }">
          <!-- 权重 -->
          <template v-if="column.key === 'platform_id'">
            {{ (Platforms.find(item => item.value === record[column.key]) || {}).text || '-' }}
          </template>
          <template v-else>
            {{ record[column.key] || '-' }}
          </template>
          <!-- 操作 -->
          <!-- <template v-if="column.key === 'operation'">
            <a class="operation-item" @click="touchPreview(record)">预览</a>
            <a class="operation-item" @click="deleted(record)">删除</a>
          </template> -->
        </template>
      </a-table>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, nextTick, onBeforeMount } from 'vue'
import { message, Modal } from 'ant-design-vue'
import { dramaLogList } from '@/api/copyright'
import { Platforms } from '@/utils/constantList'

// 数据源
let dataSource = ref(null)
// 剧名
let nameModel = ref(undefined)
let name = ref(undefined)
// 加载
let isLoading = ref(false)
// 表头
let columns = reactive([
  {
    title: '时间',
    dataIndex: 'date_time',
    key: 'date_time'
  },
  {
    title: '短剧',
    dataIndex: 'drama_name',
    key: 'drama_name'
  },
  {
    title: '平台',
    dataIndex: 'platform_id',
    key: 'platform_id',
  },
  {
    title: '变化前',
    dataIndex: 'old_content',
    key: 'old_content'
  },
  {
    title: '变化后',
    dataIndex: 'content',
    key: 'content'
  }
])
// 分页
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})

// 钩子函数 - onBeforeMount
onBeforeMount(() => {
  getData()
})

// 表格change
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getData()
}

// 查询
function touchSearch () {
  // 调整参数
  name.value = nameModel.value
  pagination.current = 1
  // 获取列表
  getData()
}

// 重置
function touchReset () {
  nameModel.value = undefined
  name.value = undefined
  // 初始化获取
  getData()
}

// 请求分页数据
function getData () {
  isLoading.value = true
  const params = {
    name: name.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  dramaLogList(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}
</script>

<style lang="less" scoped>
.content {
  padding: 24px 0;
  .tool-view {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .operation-item {
    margin-right: 8px;
  }
}

</style>